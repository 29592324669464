
const MAIL_LOG_TYPE = {
  MAIL: 1,
  STATUS: 2,
  AUTOSYSTEM: 3,
  MESSAGE: 4,
};

const MAIL_LOG_PAGE = {
  CMSUSER: 1,
  UNIVERSITY: 2,
  UNIVERSITYTIE: 3,
  JUDGMENT: 4,
  APPLICATIONS: 5,
  REQUIREDDOC: 6,
  INTERIMREPS: 7,
  REPORTS: 8,
  CHANGE: 9,
  GASUSERAPP: 10,
  MESSAGE: 11,
};

const MAIL_LOG_TYPE_OPTION = {
  [MAIL_LOG_TYPE.MAIL]: 'メール送信',
  [MAIL_LOG_TYPE.STATUS]: 'ステータス変更',
  [MAIL_LOG_TYPE.AUTOSYSTEM]: '受付完了',
  [MAIL_LOG_TYPE.MESSAGE]: 'メッセージ',
};
const MAIL_LOG_PAGE_OPTION = {
  [MAIL_LOG_PAGE.CMSUSER]: '管理者',
  [MAIL_LOG_PAGE.UNIVERSITY]: '登録大学',
  [MAIL_LOG_PAGE.UNIVERSITYTIE]: 'ユーザー紐付け',
  [MAIL_LOG_PAGE.JUDGMENT]: '合否設定',
  [MAIL_LOG_PAGE.APPLICATIONS]: '助成申請',
  [MAIL_LOG_PAGE.CHANGE]: '計画変更申請',
  [MAIL_LOG_PAGE.REQUIREDDOC]: '助成手続き書類',
  [MAIL_LOG_PAGE.INTERIMREPS]: '進捗状況報告書',
  [MAIL_LOG_PAGE.REPORTS]: '成果報告書',
  [MAIL_LOG_PAGE.GASUSERAPP]: '申請画面',
  [MAIL_LOG_PAGE.MESSAGE]: 'メッセージ',
};
const MAIL_LOG_PAGE_OPTION_2 = {
  [MAIL_LOG_PAGE.CMSUSER]: '財団から 管理者',
  [MAIL_LOG_PAGE.UNIVERSITY]: '財団から 登録大学',
  [MAIL_LOG_PAGE.UNIVERSITYTIE]: '財団から ユーザー紐付け',
  [MAIL_LOG_PAGE.JUDGMENT]: '財団から 合否設定',
  [MAIL_LOG_PAGE.APPLICATIONS]: '財団から 助成申請',
  [MAIL_LOG_PAGE.CHANGE]: '財団から 計画変更申請',
  [MAIL_LOG_PAGE.REQUIREDDOC]: '財団から 助成手続き書類',
  [MAIL_LOG_PAGE.INTERIMREPS]: '財団から 進捗状況報告書',
  [MAIL_LOG_PAGE.REPORTS]: '財団から 成果報告書',
  [MAIL_LOG_PAGE.GASUSERAPP]: 'ユーザから 申請画面',
  [MAIL_LOG_PAGE.MESSAGE]: '財団から メッセージ',
};
export default {
  MAIL_LOG_TYPE,
  MAIL_LOG_PAGE,
  MAIL_LOG_TYPE_OPTION,
  MAIL_LOG_PAGE_OPTION,
  MAIL_LOG_PAGE_OPTION_2,
};
