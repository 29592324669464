<template>
  <div class="CmsContentsFiledsList mailLogList">
    <div class="contentsWidth mx-auto mt-4">

      <div class="">
        <p class="title mb-0">メール送信履歴 - 一覧</p>
        <hr class="title">
      </div>

      <div id="serchWrap" class="mt-4">
        <div class="bold">絞り込み条件</div>
        <div class="search_area flex flex-wrap">
          <div class="searchShort inlineBlock searchSpace">
            <div>年度</div>
            <div>
              <b-form-select
                v-model="search.year"
                @change="regetList(search.year)"
                :options="yearList">
                <template v-slot:first>
                  <option :value="''">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>送信先氏名</div>
            <div>
              <b-form-input
                v-model="search.to_name"
                placeholder="入力してください。"/>
            </div>
          </div>
          <div class="searchMiddleLong inlineBlock searchSpace">
            <div>送信先メールアドレス</div>
            <div>
              <b-form-input
                v-model="search.to_mail_address"
                placeholder="入力してください。"/>
            </div>
          </div>
          <div class="searchMiddleLong inlineBlock searchSpace">
            <div>タイトル</div>
            <div>
              <b-form-input
                v-model="search.title"
                placeholder="入力してください。"/>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>送信者名</div>
            <div>
              <b-form-input
                v-model="search.from_name"
                placeholder="入力してください。"/>
            </div>
          </div>

          <div class="searchMiddle inlineBlock searchSpace">
            <div>送信元機能</div>
            <div>
              <b-form-select
                :options="logTypeOption"
                v-model="search.log_type">
                <template v-slot:first>
                  <option :value="''">-- 未選択 --</option>
                  <!-- <option :value="null">区分なし</option> -->
                </template>
              </b-form-select>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>送信元ページ</div>
            <div>
              <b-form-select
                :options="logPageOption"
                v-model="search.log_page">
                <template v-slot:first>
                  <option :value="''">-- 未選択 --</option>
                  <!-- <option :value="null">区分なし</option> -->
                </template>
              </b-form-select>
            </div>
          </div>
        </div>
      </div>

      <div class="contentsWidth mt-2">
        <div class="flex flex-between my-2">
          <b-pagination
            class="mb-1"
            aria-controls="fieldTable"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                v-model="perPage"
                :options="pageOptions"
              />
            </div>
          </div>
        </div>
      </div>

      <div id="tableWrap" class="contentsWidth mt-2">
        <b-table striped hover
          id='fieldTable'
          table-class="cmsAppMailLogTable"
          thead-class="tableHead"
          tbody-tr-class="dataWrap"
          :items="mailLogList"
          :fields="header"
          :filter="search"
          :filter-function="filtering"
          @filtered="onFiltered"
          show-empty
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template v-slot:emptyfiltered="scope">
            <div class="flex flexCenter">条件に一致するデータがありません。</div>
          </template>
          <template v-slot:cell(type)="row">
            {{row.item.typeText}}
          </template>
          <template v-slot:cell(page)="row">
            <div v-if="row.item.from_name === 'システム'">ユーザから</div><div v-else>財団から</div><div>{{row.item.pageText}}</div>
          </template>
          <template v-slot:cell(created_at)="row">
            <a
              @click="setModalMsg(row.item)"
              class="viewMail bold">
            {{formatDataSet(row.item.created_at)}}</a>
          </template>
        </b-table>
      </div>

      <div class="contentsWidth mt-2" v-if="totalRows > 0">
        <div class="flex flex-between my-2">
          <b-pagination
            class="mb-1"
            aria-controls="fieldTable"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                v-model="perPage"
                :options="pageOptions"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="mailLogModal"
      ref="mailTextModal"
      size="lg"
      :title="modalMail.title"
      title-class="mailTitleModal"
      :ok-only="true">
      <p class="mb-0">to: {{modalMail.to_mail_address}} ({{modalMail.to_name}})</p>
      <!-- <p class="mb-0">from: {{modalMail.from_user_name}}</p> -->
      <p class="mb-0">送信日: {{modalMail.created_at}}</p>
      <hr/>
      <div class="modalMailBody">
        {{modalMail.text}}
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/modules/api';
import CONST_MAIL_LOG_STATUS from '@/constants/mailLogStatus';
// import download from '@/modules/download';

export default {
  name: 'CmsApplicationsList',
  data() {
    return {
      dbMailLogList: [],
      header: [
        {
          key: 'to_name',
          label: '送信先氏名',
          sortable: true,
          class: '',
        },
        {
          key: 'to_mail_address',
          label: '送信先メールアドレス',
          sortable: true,
          class: 'address_wrap',
        },
        {
          key: 'from_name',
          label: '送信者名',
          sortable: true,
          class: '',
        },
        {
          key: 'title',
          label: '件名',
          sortable: true,
          class: '',
        },
        {
          key: 'type',
          label: '機能',
          sortable: true,
          class: '',
        },
        {
          key: 'page',
          label: 'ページ',
          sortable: true,
          class: '',
        },
        {
          key: 'created_at',
          label: '送信履歴',
          sortable: true,
          class: '',
        },
      ],
      pageOptions: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 },
      ],
      search: {
        year: '',
        to_name: '',
        to_mail_address: '',
        title: '',
        from_name: '',
        log_type: '',
        log_page: '',
      },
      seriesList: [],
      yearList: [],
      logTypeOption: CONST_MAIL_LOG_STATUS.MAIL_LOG_TYPE_OPTION,
      logPageOption: CONST_MAIL_LOG_STATUS.MAIL_LOG_PAGE_OPTION_2,
      isInit: true,
      oldPage: 1,
      perPage: 50,
      currentPage: 1,
      totalRows: 0,
      modalMail: {
        title: '',
        text: '',
        to_mail_address: '',
        to_name: '',
        from_user_name: '',
        created_at: '',
      },
      sortBy: 'created_at',
      sortDesc: true,
    };
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading', 'CmsTop');
    const year = moment().format('YYYY');
    await this.fetchList(year);
    this.$store.dispatch('page/offLoading', 'CmsTop');
  },
  methods: {
    // フィルター用 search変更時に各行に対して走る
    filtering(lineData, search) {
      const year = search.year === '' || lineData.year === Number(search.year);
      const toName = search.to_name === '' || (lineData.to_name && lineData.to_name.includes(search.to_name));
      const toMailAddress = search.to_mail_address === '' || (lineData.to_mail_address && lineData.to_mail_address.includes(search.to_mail_address));
      const title = search.title === '' || (lineData.title && lineData.title.includes(search.title));
      const fromName = search.from_name === '' || (lineData.from_name && lineData.from_name.includes(search.from_name));
      const logType = search.log_type === '' || lineData.type === Number(search.log_type);
      const logPage = search.log_page === '' || lineData.page === Number(search.log_page);
      return year && toName && toMailAddress && title && fromName && logType && logPage;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchList(nowYear) {
      const param = {
        year: nowYear,
      };
      const response = await api.send('/api/cms/applications/mailLog/list', param)
        .catch((err) => {
          console.log(err);
        });
      this.dbMailLogList = response.data.list;
      this.yearList = response.data.yearList;
      // this.$set(this.search, 'year', response.data.selectedYear);
      this.totalRows = this.dbMailLogList.length;
    },
    setModalMsg(mail) {
      this.$set(this.modalMail, 'title', mail.title);
      this.$set(this.modalMail, 'text', mail.text);
      this.$set(this.modalMail, 'to_mail_address', mail.to_mail_address);
      this.$set(this.modalMail, 'to_name', mail.to_name);
      this.$set(this.modalMail, 'from_user_name', mail.from_name);
      this.$set(this.modalMail, 'created_at', moment(mail.created_at).format('YYYY/MM/DD HH:mm:ss'));
      this.$refs.mailTextModal.show();
    },
    formatDataSet(date) {
      return moment(date).format('YYYY/MM/DD HH:mm');
    },
  },
  computed: {
    nowPageCount() {
      if (this.totalRows === 0) {
        return 0;
      }
      let maxPage = Math.floor(this.totalRows / this.perPage);
      // 上記計算にあまりがあれば、ページ数をプラス1
      const mod = this.totalRows % this.perPage;
      let lastPageItemNum;
      if (mod !== 0) {
        maxPage += 1;
        lastPageItemNum = mod;
      } else {
        lastPageItemNum = this.perPage;
      }
      // 最終ページ以外は、現在の表示件数設定の値
      if (this.currentPage < maxPage) {
        return this.perPage;
      }
      // 最終ページが表示件数ぴったりの場合以外は、端数を表示
      return lastPageItemNum;
    },
    mailLogList() {
      const mailLogList = this.dbMailLogList.map((mailLog) => {
        return {
          id: mailLog.id,
          year: mailLog.year,
          to_name: mailLog.to_name,
          to_mail_address: mailLog.to_mail_address,
          from_name: mailLog.from_name,
          title: mailLog.title,
          type: mailLog.type,
          page: mailLog.page,
          typeText: CONST_MAIL_LOG_STATUS.MAIL_LOG_TYPE_OPTION[mailLog.type],
          pageText: CONST_MAIL_LOG_STATUS.MAIL_LOG_PAGE_OPTION[mailLog.page],
          created_at: mailLog.created_at,
          text: mailLog.text,
        };
      });
      return mailLogList;
    },
  },
};
</script>

<style>
  .cmsAppMailLogTable thead th:nth-of-type(1) {
    width: 70px !important;
  }
  .cmsAppMailLogTable thead th:nth-of-type(2) {
    width: 370px!important;
    min-width: 255px;
  }
  .cmsAppMailLogTable thead th:nth-of-type(3) {
    width: 70px !important;
  }
  .cmsAppMailLogTable thead th:nth-of-type(4) {
    min-width: 200px !important;
  }
  .cmsAppMailLogTable thead th:nth-of-type(5) {
    width: 120px!important;
    min-width: 105px;
  }
  .cmsAppMailLogTable thead th:nth-of-type(6) {
    width: 150px!important;
    min-width: 140px!important;
  }
/*  .cmsAppMailLogTable thead th:nth-of-type(7) {
    width: 55px !important;
  }*/
  #mailLogModal .modal-header{
    display: flex !important;
  }
  .address_wrap{
    word-break: break-all;
  }
</style>

<style scoped>
  #serchWrap input, #serchWrap select {
    height: 50px;
  }

  #NumberWrap {
    height: 50px;
  }

  .search_area {
    background: #DDD;
    padding: 10px;
    margin-bottom: 30px;
  }
  .searchLong {
    width: 450px;
  }

  .searchMiddleLong {
    width: 320px;
  }
  .searchMiddle {
    width: 205px;
  }

  .searchShort {
    width: 135px;
  }

  .searchSpace {
    margin-right: 15px;
    margin-bottom: 5px;
  }

  .err-wrap>p{
    color: #dc3545;
  }
  .modalMailBody {
    white-space: pre-line;
  }

</style>
